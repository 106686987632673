import React, { useState, useEffect, useMemo } from 'react';
import OutputsAccountTable from './OutputsAccountTable';
import ForecastDropdown from "./ForecastDropdown";
import ForecastMonthDropdown from "./ForecastMonthDropdown";
import Box from "@mui/material/Box";
import CustomerDropdown from "./CustomerDropdown";
import ForecastProductDropdown from "./ForecastProductDropdown";
import axiosInstance from "../AxiosInstance";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

const ForecastOutputsAccount = () => {
    const [selectedForecastId, setSelectedForecastId] = useState(null);
    const [selectedForecastMonth, setSelectedForecastMonth] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedChannelId, setSelectedChannelId] = useState(null);
    const [startPeriod, setStartPeriod] = useState(1);
    const [summaryData, setSummaryData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const storedForecast = JSON.parse(localStorage.getItem('selectedForecast'));
        const storedForecastMonth = localStorage.getItem('selectedForecastMonth');

        if (storedForecast?.forecastId) {
            setSelectedForecastId(storedForecast.forecastId);
            setStartPeriod(storedForecast.startPeriod);
        }

        if (storedForecastMonth) {
            setSelectedForecastMonth(storedForecastMonth);
        }
    }, []);

    useEffect(() => {
        if (selectedForecastId && selectedForecastMonth !== null) {
            const fetchAccountSummary = async () => {
                try {
                    const params = {
                        periodIndex: selectedForecastMonth,
                        customerId: selectedCustomerId,
                        channelId: selectedChannelId,
                        productId: selectedProductId
                    };
                    const response = await axiosInstance.get(`/forecasts/${selectedForecastId}/outputs-account`, { params });
                    setSummaryData(response.data);
                    setError(null);
                } catch (error) {
                    console.error("Failed to fetch account summary:", error);
                    setSummaryData(null);
                    if (error.response && error.response.status === 404) {
                        setError("Account summary not found.");
                    } else {
                        setError("An unexpected error occurred.");
                    }
                }
            };

            fetchAccountSummary();
        }
    }, [selectedForecastId, selectedForecastMonth, selectedCustomerId, selectedChannelId, selectedProductId]);

    const handleForecastSelected = (id, period) => {
        localStorage.setItem('selectedForecast', JSON.stringify({ forecastId: id, startPeriod: period }));
        setSelectedForecastId(id);
        if (period !== null) {
            setStartPeriod(period);
        }
    };
    
    const handleCustomerSelected = (selection) => {
        if (selection.type === 'channel') {
            setSelectedChannelId(selection.id);
            setSelectedCustomerId(null);
        } else {
            setSelectedCustomerId(selection.id);
            setSelectedChannelId(null);
        }
    };

    const handleForecastMonthSelected = async (forecastMonth) => {
        localStorage.setItem('selectedForecastMonth', forecastMonth);
        setSelectedForecastMonth(forecastMonth);
    };

    const handleProductSelected = ({ productId }) => {
        setSelectedProductId(productId);
    };

    const aggregateData = (data) => {
        if (!data) {
            return {};
        }

        const keys = ['baseUnits', 'promoUnits', 'grossRevenue', 'triggers', 'netRevenue', 'fixedSpend', 'cogs', 'logs', 'grossProfit', 'grossProfitPercent'];
        const aggregatedData = {};

        keys.forEach(key => {
            if (key !== 'grossProfitPercent') {
                let monthlyTotals = data[key].slice(0, 12);
                let fyTotal = monthlyTotals.reduce((acc, value) => acc + value, 0);
                aggregatedData[key] = [...monthlyTotals, fyTotal];
            }
        });

        const grossProfitTotals = data['grossProfit'].slice(0, 12);
        const grossRevenueTotals = data['grossRevenue'].slice(0, 12);

        const fyGrossProfit = grossProfitTotals.reduce((acc, value) => acc + value, 0);
        const fyGrossRevenue = grossRevenueTotals.reduce((acc, value) => acc + value, 0);
        const fyGrossProfitPercent = fyGrossRevenue === 0 ? 0 : (fyGrossProfit / fyGrossRevenue) * 100;

        aggregatedData['grossProfitPercent'] = grossProfitTotals.map((profit, index) => {
            const revenue = grossRevenueTotals[index];
            return revenue === 0 ? 0 : (profit / revenue) * 100;
        });

        aggregatedData['grossProfitPercent'].push(fyGrossProfitPercent);

        return aggregatedData;
    };

    const aggregatedData = useMemo(() => {
        if (summaryData) {
            return aggregateData(summaryData);
        }
        return {};
    }, [summaryData]);

    const startMonth = summaryData?.startMonth || 1;

    const handleDownloadCsv = async () => {
        setLoading(true);
        try {
            const params = {
                periodIndex: selectedForecastMonth,
                channelId: selectedChannelId,
                customerId: selectedCustomerId,
                productId: selectedProductId
            };
            const response = await axiosInstance.get(`/forecasts/${selectedForecastId}/outputs-account-csv`, {
                params,
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `OutputsAccount_${selectedForecastId}_${selectedForecastMonth}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Failed to download CSV:", error);
            setError("Failed to download CSV. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pb: 2 }}>
                <ForecastDropdown
                    selectedForecastId={selectedForecastId}
                    onForecastSelected={handleForecastSelected}
                />
                <ForecastMonthDropdown
                    selectedForecastMonth={selectedForecastMonth}
                    onForecastMonthSelected={handleForecastMonthSelected}
                    startMonth={startPeriod}
                />
                <CustomerDropdown onCustomerSelected={handleCustomerSelected} channelSelectable={true} />
                <ForecastProductDropdown
                    forecastId={selectedForecastId}
                    customerId={selectedCustomerId}
                    periodIndex={selectedForecastMonth}
                    onProductSelected={handleProductSelected}
                />
                <Tooltip title="Download CSV">
                    <span>
                        <IconButton onClick={handleDownloadCsv} aria-label="download csv" disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : <FileDownloadIcon />}
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
            {error ? (
                <div>{error}</div>
            ) : (
                <Box>
                    {selectedForecastId && selectedForecastMonth !== null && selectedForecastMonth > -1 && (
                        <OutputsAccountTable data={aggregatedData} startMonth={startMonth} />
                    )}
                </Box>
            )}
        </div>
    );
};

export default ForecastOutputsAccount;
